<template>
  <div>
    <div class="table-responsive">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-bordered"
      >
        <template>
          <tbody>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-preliminary"
                    v-model="status.preliminary"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Preliminary</td>
            </tr>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-revision"
                    v-model="status.revision"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Revision</td>
            </tr>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-final"
                    v-model="status.final"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Final</td>
            </tr>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-withdrawal"
                    v-model="status.withdrawal"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Withdrawal</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tujuan",
  props: {
    status: {
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>
