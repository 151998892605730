<template>
  <div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <b-row class="my-1 d-flex align-items-center mb-3">
                  <b-col sm="3">
                    <label>Project Code :</label>
                  </b-col>
                  <b-col sm="9">
                    <b-select
                      :options="formattedProjectOptions"
                      size="sm"
                      v-model="form.project.code"
                    ></b-select>
                  </b-col>
                </b-row>
                <b-row class="my-1 d-flex align-items-center mb-3">
                  <b-col sm="3">
                    <label>Attn :</label>
                  </b-col>
                  <b-col sm="9">
                    <input
                      type="text"
                      class="form-control"
                      size="sm"
                      v-model="form.attention"
                    />
                  </b-col>
                </b-row>
                <b-row class="my-1 d-flex align-items-center mb-3">
                  <b-col sm="3">
                    <label>Subject :</label>
                  </b-col>
                  <b-col sm="9">
                    <input
                      type="text"
                      class="form-control"
                      size="sm"
                      v-model="form.subject"
                    />
                  </b-col>
                </b-row>
              </div>
              <div
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <b-row class="my-1 d-flex align-items-center mb-3">
                  <b-col sm="3">
                    <label>Company :</label>
                  </b-col>
                  <b-col sm="9">
                    <b-select
                      :options="formattedCompanyOptions"
                      size="sm"
                      v-model="form.company.code"
                    ></b-select>
                  </b-col>
                </b-row>
                <b-row class="my-1 d-flex align-items-center mb-3">
                  <b-col sm="3">
                    <label>Date :</label>
                  </b-col>
                  <b-col sm="9">
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="form.tanggal"
                        id="example-date-input"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          :no-flip="true"
                          v-model="form.tanggal"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row class="my-1 d-flex align-items-center mb-3">
                  <b-col sm="3">
                    <label>Transmittal Number :</label>
                  </b-col>
                  <b-col sm="9">
                    <input
                      type="text"
                      class="form-control"
                      size="sm"
                      v-model="form.nomor_transmittal"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="text-right">
                  <div class="btn-group">
                    <button class="btn btn-primary btn-sm" @click="onSaveDraft">
                      Save as Draft
                    </button>
                    <button
                      class="btn btn-success btn-sm"
                      @click="onSaveNormal"
                    >
                      Save
                    </button>
                    <button
                      class="btn btn-secondary btn-sm"
                      :disabled="loading"
                      ref="kt_print"
                      @click="onPrint"
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div>
              <b-tabs content-class="mt-3">
                <b-tab title="Purpose" active>
                  <Tujuan :tujuan="dataTujuan" />
                </b-tab>
                <b-tab title="Status">
                  <Status :status="dataStatus" />
                </b-tab>
                <b-tab title="Design">
                  <Design
                    :project="form.project"
                    @setDataDesign="setDesign"
                    :data="dataDesign"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tujuan from "./createComponent/Tujuan.vue";
import Status from "./createComponent/Status.vue";
import Design from "./createComponent/Design.vue";
import { FILTER_PROJECT_DETAIL } from "@/core/services/store/project.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { arrayMoreThanOne } from "@/core/helper/array-validation.helper";
import { GET_COMPANY_PROJECT } from "@/core/services/store/company.module";
import {
  POST_TRANSMITTAL,
  POST_TRANSMITTAL_DRAFT,
  GET_TRANSMITTAL_DETAIL,
  POST_DOWNLOAD_ATTACHMENT_TRANSMITTAL,
} from "@/core/services/store/transmittal.module";
import { showToast } from "@/core/helper/toast.helper";

export default {
  name: "CreateTransmittal",
  components: {
    Tujuan,
    Status,
    Design,
  },
  data() {
    return {
      form: {
        project: {
          code: null,
          name: null,
        },
        company: {
          code: null,
          name: null,
        },
        attention: null,
        tanggal: null,
        subject: null,
        nomor_transmittal: null,
        file: null,
      },
      companyOptions: [],
      dataStatus: {
        preliminary: 0,
        revision: 0,
        final: 0,
        withdrawal: 0,
      },
      dataTujuan: {
        review_approval: 0,
        stamp_signature: 0,
        information: 0,
        information_installation: 0,
        copies: 0,
      },
      dataDesign: [],
    };
  },
  methods: {
    onDownload(path) {
      console.log("path", path);
      if (path !== "") {
        // set spinner to submit button
        const submitButton = this.$refs["kt_print"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          this.$store
            .dispatch(POST_DOWNLOAD_ATTACHMENT_TRANSMITTAL, { file_url: path })
            .then(() => {
              showToast("Notification", "Print Data Success!", "success");

              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            })
            .catch((error) => {
              showToast("Notification", error.data.message, "danger");
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            });
        }, 2000);
      }
    },
    onPrint() {
      if (this.form.file === null) {
        this.$bvToast.toast("File Not Found!", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        this.onDownload(this.form.file);
      }
    },

    onSaveDraft() {
      this.onSave("DRAFT");
    },
    onSaveNormal() {
      this.onSave("NORMAL");
    },
    onSave(type) {
      if (this.form.project?.code === null) {
        this.$bvToast.toast("Please Choose Project Please.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else if (this.form.company?.code === null) {
        this.$bvToast.toast("Please Choose Company First.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        let dataSubmit = this.mappingObjectFromUI();
        if (type === "DRAFT") {
          this.$store.dispatch(POST_TRANSMITTAL_DRAFT, dataSubmit).then(() => {
            this.$router.push("/project/transmittal");
          });
        } else {
          this.$store.dispatch(POST_TRANSMITTAL, dataSubmit).then(() => {
            this.$router.push("/project/transmittal");
          });
        }
      }
    },
    mappingObjectFromUI() {
      let paramID =
        this.$route.params.id === undefined ? null : +this.$route.params.id;
      const result = {
        id: paramID,
        number: this.form.nomor_transmittal,
        company: {
          code: this.form.company.code,
          name: null,
        },
        attention: this.form.attention,
        subject: this.form.subject,
        date: this.form.tanggal,
        project: {
          code: this.form.project.code,
          nickname: this.form.project.nickname,
          name: this.form.project.name,
        },
        review_approval:
          this.dataTujuan.review_approval === null
            ? 0
            : parseInt(this.dataTujuan.review_approval),
        stamp_signature:
          this.dataTujuan.stamp_signature === null
            ? 0
            : parseInt(this.dataTujuan.stamp_signature),
        information:
          this.dataTujuan.information === null
            ? 0
            : parseInt(this.dataTujuan.information),
        information_installation:
          this.dataTujuan.information_installation === null
            ? 0
            : parseInt(this.dataTujuan.information_installation),
        copies:
          this.dataTujuan.copies === null
            ? 0
            : parseInt(this.dataTujuan.copies),
        preliminary:
          this.dataStatus.preliminary === null
            ? 0
            : parseInt(this.dataStatus.preliminary),
        revision:
          this.dataStatus.revision === null
            ? 0
            : parseInt(this.dataStatus.revision),
        final:
          this.dataStatus.final === null ? 0 : parseInt(this.dataStatus.final),
        withdrawal:
          this.dataStatus.withdrawal === null
            ? 0
            : parseInt(this.dataStatus.withdrawal),
        design: this.dataDesign,
        file: this.file,
      };

      return result;
    },

    setDesign(listDesign) {
      this.dataDesign = listDesign;
    },
    getListProject() {
      this.onProjectFilter(this.form);
    },
    onProjectFilter(param) {
      const filter = {
        jobdesk: "interface",
        params: {
          code: param.project.code === null ? "" : param.project.code,
        },
      };
      this.$store.dispatch(FILTER_PROJECT_DETAIL, filter);
    },

    getCompanyByProject(projectID) {
      this.$store.dispatch(GET_COMPANY_PROJECT, projectID);
    },

    getTransmittalDetail(id) {
      if (id !== undefined) {
        this.$store
          .dispatch(GET_TRANSMITTAL_DETAIL, id)
          .then(() => this.mappingDetailToUI(this.getterTransmittalDetail));
      }
      console.log("tranmittalDeya", this.getterTransmittalDetail);
    },

    mappingDetailToUI(detail) {
      Object.assign(this.form, {
        attention: detail.attention,
        project: detail.project,
        file: detail.file,
        company: {
          code:
            detail.company.code === undefined
              ? detail.company_code
              : detail.company.code,
          name: detail.company.name,
        },
        subject: detail.subject,
        nomor_transmittal: detail.number,
        tanggal: detail.date,
      });

      Object.assign(this.dataStatus, {
        preliminary: detail.preliminary,
        revision: detail.revision,
        final: detail.final,
        withdrawal: detail.withdrawal,
      });

      Object.assign(this.dataTujuan, {
        review_approval: detail.review_approval,
        stamp_signature: detail.stamp_signature,
        information: detail.information,
        information_installation: detail.information_installation,
        copies: detail.copies,
      });

      detail.design.map((des) => {
        this.dataDesign.push({
          ...des,
          hapus: "0",
        });
      });

      console.log("forms", this.form);
    },
  },
  async created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Transmittal" }]);
    this.getListProject();
    this.getTransmittalDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters([
      "currentProjectDetails",
      "currentCompanyProject",
      "getterTransmittalDetail",
      "loadingTransmittal",
    ]),
    formattedProjectOptions() {
      let projects = [];
      if (arrayMoreThanOne(this.currentProjectDetails)) {
        projects = this.currentProjectDetails.map((project) => {
          return {
            value: project.code,
            text: project.code + "-" + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    formattedCompanyOptions() {
      let companies = [];
      if (arrayMoreThanOne(this.currentCompanyProject)) {
        companies = this.currentCompanyProject.map((company) => {
          return {
            value: company.code,
            text: company.code + "-" + company.name,
          };
        });
      }

      return [{ value: null, text: "Choose Company" }, ...companies];
    },

    currentProjectCode() {
      return this.form.project.code;
    },

    loading() {
      return this.loadingTransmittal;
    },
  },
  watch: {
    currentProjectCode(value) {
      if (!value) {
        Object.assign(this.form, {
          project: {
            code: null,
            name: null,
          },
          company: {
            code: null,
            name: null,
          },
          attention: null,
          tanggal: null,
          subject: null,
          nomor_transmittal: null,
        });

        this.companyOptions = [{ value: null, text: "Choose Company" }];
        return;
      }

      const project = this.currentProjectDetails.find((x) => x.code === value);

      Object.assign(this.form.project, {
        ...project,
      });

      this.getCompanyByProject(value);
    },

    $route(to) {
      this.$store.dispatch(GET_TRANSMITTAL_DETAIL, to.params);
    },
  },
};
</script>
