<template>
  <div>
    <div class="table-responsive">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-bordered"
      >
        <template>
          <tbody>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-approval"
                    v-model="tujuan.review_approval"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Review & Approval</td>
            </tr>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-stamps"
                    v-model="tujuan.stamp_signature"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Stample & Signature</td>
            </tr>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-information"
                    v-model="tujuan.information"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Information</td>
            </tr>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-installation"
                    v-model="tujuan.information_installation"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Construction & Installation</td>
            </tr>
            <tr>
              <td style="width: 5em;">
                <div>
                  <b-form-checkbox
                    id="checkbox-copy"
                    v-model="tujuan.copies"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td>Copies</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tujuan",
  props: {
    tujuan: {
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>
