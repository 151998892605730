<template>
  <div>
    <div class="text-right mb-3" @click.prevent="addTransmittalDesign()">
      <i class="fas fa-plus text-primary"></i>
    </div>
    <div class="table-responsive">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr>
            <th>Status</th>
            <th style="width: 25em;">Numbering</th>
            <th style="width: 5em;">Revision</th>
            <th>Title</th>
            <th>Delete</th>
          </tr>
        </thead>
        <template v-for="(item, i) in data">
          <tbody v-bind:key="i">
            <tr>
              <td>
                {{
                  item.design.last_status ? item.design.last_status.name : ""
                }}
              </td>
              <td>{{ item.design.numbering }}</td>
              <td>{{ item.design.revision }}</td>
              <td>{{ item.design.title }}</td>
              <td>
                <div>
                  <b-form-checkbox
                    v-model="item.hapus"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>

    <!-- modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      no-close-on-backdrop
      size="xl"
      title="Add Design Transmittal"
    >
      <div class="d-block text-center">
        <div>
          <FormDesign
            :project="project"
            :design="data"
            @setDesign="setDesign"
          />
        </div>
      </div>
    </b-modal>
    <!-- end modal -->
  </div>
</template>

<script>
import FormDesign from "../addDesign/FormDesign.vue";

export default {
  name: "DesingTransmittal",
  components: {
    FormDesign
  },
  props: {
    project: {
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      deleteList: ""
    };
  },
  methods: {
    setDesign(listDesign) {
      listDesign.map(des => {
        this.data.push({
          id: null,
          design: des,
          hapus: "0"
        });
      });
      this.$emit("setDataDesign", this.data);
      this.closeTransmittalDesign();
    },
    addTransmittalDesign() {
      this.$refs["my-modal"].show();
    },
    closeTransmittalDesign() {
      this.$refs["my-modal"].hide();
    }
  }
};
</script>
