<template>
  <div>
    <div class="row mb-5">
      <div class="col-md-4 py-2 col-sm-12">
        <button
          class="btn btn-success btn-md mr-2"
          @click="onTambahData"
          :disabled="getLengthDataChecked === 0"
        >
          <i class="fa fa-plus" />Add
        </button>

        <button class="btn btn-info btn-md mr-2" @click="onLoadDesign">
          <i class="fa fa-sync" />Load Design
        </button>
      </div>

      <div class="col-md-4 offset-md-4 py-2 col-sm-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="flaticon2-search-1"></i>
            </span>
          </div>

          <input
            type="text"
            class="form-control"
            placeholder="Search"
            v-model="filter.search"
          />
        </div>
      </div>
    </div>
    <div class="table-responsive mt-3">
      <complete-table :loading="loadingTransmittal" :data="this.data.dld">
        <template #header>
          <tr class="text-left">
            <th></th>
            <th style="min-width: 50px" class="pl-7">
              <span class="text-dark-75">No.</span>
            </th>
            <th style="min-width: 200px" class="pl-7">
              <span class="text-dark-75">Status</span>
            </th>
            <th style="min-width: 200px" class="pl-7">
              <span class="text-dark-75">Type Document</span>
            </th>
            <th style="min-width: 100px">Numbering</th>
            <th style="min-width: 100px">Revision</th>
            <th style="min-width: 100px">Title</th>
          </tr>
        </template>
        <template #defaultBody="{ item, i }">
          <tr v-bind:key="i">
            <td class="pl-0 ">
              <b-form-checkbox
                value="1"
                unchecked-value="0"
                v-model="item.checkedItem"
              ></b-form-checkbox>
            </td>

            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ i + 1 }}
              </span>
            </td>

            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.status }}
              </span>
            </td>

            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.type_code }}
              </span>
            </td>

            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.numbering }}
              </span>
            </td>

            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.revisi }}
              </span>
            </td>

            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.title }}
              </span>
            </td>
          </tr>
        </template>
      </complete-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompleteTable from "../../../../content/widgets/advance-table/CompleteTable.vue";
import { GET_TRANSMITTAL_MONITORING_SHOP_DRAWING } from "@/core/services/store/transmittal.module.js";
export default {
  name: "FormDesignTransmittal",
  components: {
    CompleteTable
  },
  props: {
    project: {
      required: true
    },
    design: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      data: {
        dld: [],
        projects: [],
        dldOri: []
      },
      filter: {
        search: null
      }
    };
  },

  mounted() {},
  methods: {
    onTambahData() {
      let newDesign = [];
      let dataChecked = this.data.dld.filter(c => c.checkedItem === "1");
      dataChecked.map(data => {
        newDesign.push({
          id: data.id,
          numbering: data.numbering,
          last_status: {
            code: data.status,
            name: data.statusName
          },
          revision: data.revision,
          title: data.title,
          type_code: data.type_code
        });
      });

      this.$emit("setDesign", newDesign);
    },

    onLoadUncheckedData() {
      this.data.dld = this.data.dld.filter(c => c.checkedItem == "0");
    },

    mappingDataObject(listData) {
      this.data.dld = [];
      console.log("form", listData);
      listData.map(data => {
        this.data.dld.push({
          id: data.id,
          status: data.last_status ? data.last_status.code : null,
          statusName: data.last_status ? data.last_status.name : null,
          numbering: data.numbering,
          revision: data.revision_version,
          title: data.title,
          checkedItem: "0",
          type_code: data.type_code
        });
      });

      this.data.dldOri = [...this.data.dld];
      console.log("data dld", this.data.dld);
    },
    onLoadDesign() {
      if (this.project.code === null) {
        this.$bvToast.toast("Please Choose Project First", {
          title: "Warning",
          variant: "warning",
          solid: true
        });
      } else {
        this.loadDesign(this.project.code);
      }
    },

    loadDesign(projectID) {
      this.data.dld = [];
      this.$store
        .dispatch(GET_TRANSMITTAL_MONITORING_SHOP_DRAWING, projectID)
        .then(() => this.mappingDataObject(this.currentData));
    }
  },
  computed: {
    ...mapGetters([
      "getterTransmittalMonitoringShopDrawing",
      "loadingTransmittal"
    ]),

    currentData() {
      return this.getterTransmittalMonitoringShopDrawing;
    },

    currentIdProject() {
      return this.project.code;
    },

    getLengthDataChecked() {
      let result = 0;
      if (this.data.dld.length > 0) {
        if (this.data.dld.filter(c => c.checkedItem == "1").length > 0) {
          result = 1;
        }
      }
      return result;
    },
    currentSearch() {
      return this.filter.search;
    }
  },
  watch: {
    currentSearch(value) {
      console.log(value);
      if (!value) {
        console.log("kosong");
        this.onLoadDesign();
        return;
      }

      console.log("ori", this.data.dldOri);
      this.data.dld = this.data.dldOri.filter(
        c =>
          c.status?.includes(value) ||
          c.statusName?.includes(value) ||
          c.numbering?.includes(value) ||
          c.revision?.includes(value) ||
          c.title?.includes(value) ||
          c.type_code?.includes(value)
      );

      console.log("filtered", this.data.dld);
    }
  }
};
</script>
